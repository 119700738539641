import { autoinject } from 'aurelia-framework';
import { ServiceWasteProductMatrix } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ServiceWasteProductMatrixService extends BaseApiService<ServiceWasteProductMatrix> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, ServiceWasteProductMatrix);
  }
}
