import { autoinject } from 'aurelia-framework';
import { ServiceKiloWasteProduct } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ServiceKiloWasteProductService extends BaseApiService<ServiceKiloWasteProduct> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, ServiceKiloWasteProduct);
  }
}
