import { ServiceFilterModel } from './../models/service-filter-model';
import { I18N } from 'aurelia-i18n';
import { Models } from 'models/core';
import { all, autoinject } from 'aurelia-framework';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';
import * as moment from 'moment';
import { FileAttachmentModels } from 'models/FileAttachmentModels';
import { InvoiceableService } from 'models';
import { ServiceInvoiceStatus } from 'models/ServiceInvoiceStatus';

@autoinject
export class ServiceService extends BaseApiService<Models.Service> {
  public SELECTED_SERVICE_COLUMNS = 'SELECTED_SERVICE_COLUMNS';
  public SERVICE_FILTERS_KEY = 'SERVICE_FILTERS_KEY';
  public SERVICE_LIST_FILTERS = 'SERVICE_LIST_FILTERS';

  public manualFilters = ['serviceStatusIds', 'top', 'skip', 'export', 'orderBy', 'orderByDirection'];

  constructor(
    httpClient: AuthHttpClient,
    private i18n: I18N
  ) {
    super(httpClient, Models.Service);
  }

  public getFilterString(filters: any): Array<string> {
    let results = [];

    if (filters.serviceStatusIds) {
      results.push('Status: ' + (filters.serviceStatusIds.split(',').length + ' statuser valgt'));
    }

    let props = Object.getOwnPropertyNames(filters);

    props.forEach((key) => {
      // add querystring params for all criterias, but ignore props that start with _
      if (filters[key] && !this.manualFilters.find((x) => x === key) && key.indexOf('_') !== 0) {
        results.push(key + ': ' + filters[key]);
      }
    });

    return results;
  }

  public put(
    entity: Models.Service,
    id?: number | string,
    url: string = null,
    forceSendProperties: Array<string> = []
  ): Promise<Models.Service> {
    // dont send ServiceStationStorageId and ServiceStatusId when using a regular put because,
    // storage can be updated from any of the other views, so saving it here
    // would only cause problems
    if (entity) {
      if (forceSendProperties.indexOf('ServiceStationStorageId') === -1) {
        delete entity.ServiceStationStorageId;
      }
      if (forceSendProperties.indexOf('ServiceStatusId') === -1) {
        delete entity.ServiceStatusId;
      }
      if (forceSendProperties.indexOf('PlannedDeliveryDate') === -1) {
        delete entity.PlannedDeliveryDate;
      }
      if (forceSendProperties.indexOf('InternalComment') === -1) {
        delete entity.InternalComment;
      }
    }

    return super.put(entity, id, url);
  }

  public patchValue(serviceId: number, field, value) {
    let serviceUpdate = {
      Id: serviceId,
    };

    serviceUpdate[field] = value;

    return super.put(<any>serviceUpdate, serviceId);
  }

  public updateServiceStationStorage(storageId: number, serviceId: number) {
    let serviceUpdate = {
      Id: serviceId,
      ServiceStationStorageId: storageId,
    };

    return super.put(<any>serviceUpdate, serviceId);
  }

  public updateServiceStatus(serviceStatusId: number, serviceId: number) {
    let entity = {
      Id: serviceId,
      ServiceStatusId: serviceStatusId,
    };

    return super.put(<any>entity, serviceId);
  }

  public getList(filters: any): Promise<any> {
    let queryString: string = '';

    if (filters.serviceStatusIds) {
      queryString += '?serviceStatusIds=' + filters.serviceStatusIds;
    } else {
      queryString += '?serviceStatusIds=';
    }

    let props = Object.getOwnPropertyNames(filters);

    props.forEach((key) => {
      // add querystring params for all criterias, but ignore props that start with _
      if (filters[key] != null && !this.manualFilters.find((x) => x === key) && key.indexOf('_') !== 0) {
        queryString += '&' + key + '=' + filters[key];
      }
    });

    const allTasks = [
      {
        serviceStationId: 'WashingServiceStationId',
        serviceStationName: 'WashingServiceStationName',
      },
      {
        serviceStationId: 'MeasuringServiceStationId',
        serviceStationName: 'MeasuringServiceStationName',
      },
      {
        serviceStationId: 'TestingServiceStationId',
        serviceStationName: 'TestingServiceStationName',
      },
      {
        serviceStationId: 'RepairServiceStationId',
        serviceStationName: 'RepairServiceStationName',
      },
      {
        serviceStationId: 'AntifoulingServiceStationId',
        serviceStationName: 'AntifoulingServiceStationName',
      },
      {
        serviceStationId: 'DeliveryServiceStationId',
        serviceStationName: 'DeliveryServiceStationName',
      },
    ]
      .map(({ serviceStationId, serviceStationName }) => {
        return `${serviceStationId},${serviceStationName}`;
      })
      .join(',');

    if (filters._select) {
      queryString += `&select=${filters._select},${allTasks},ServiceStationId,IsInvoicingCompatible`;
    }

    queryString += '&locale=' + this.getLocale();

    if (filters.orderBy) {
      queryString += '&orderBy=' + filters.orderBy;

      if (filters.orderByDirection) {
        queryString += '&orderByDirection=' + filters.orderByDirection;
      }
    }

    if (filters._export) {
      const _visibleFieldsParams = new URLSearchParams();
      if (filters._visibleFields) {
        _visibleFieldsParams.set('visibleFields', filters._visibleFields);
      }

      if (filters._exportOnlyVisibleFields) {
        _visibleFieldsParams.set('exportOnlyVisibleFields', filters._exportOnlyVisibleFields);
      }

      queryString += '&export=' + filters._export;
      queryString += '&' + _visibleFieldsParams.toString();
      return this.getBlob('getServices' + queryString);
    } else {
      if (filters.top) {
        queryString += '&top=' + filters.top;
      }
      if (filters.skip) {
        queryString += '&skip=' + filters.skip;
      }

      return this.getResponse('getServices' + queryString);
    }
  }

  private getLocale(): string {
    let locale = this.i18n.getLocale();
    if (locale === 'nb-NO') {
      locale = 'nb';
    }

    return locale;
  }

  public createService(serviceData: Models.CreateServiceDto): Promise<any> {
    return this.post(serviceData as any, '/create-service');
  }

  public checkCanApproveService(serviceId: number): Promise<any> {
    return this.get('check-can-approve-service/' + serviceId);
  }

  public approveService(serviceId: number): Promise<Models.Service> {
    return this.post(null, '/approve-service/' + serviceId + `?culture=${this.getLocale()}`);
  }

  public approveServiceSpecialProduct(serviceId: number): Promise<Models.Service> {
    return this.post(null, '/approve-service-special-product/' + serviceId + `?culture=${this.getLocale()}`);
  }

  public dontApproveService(serviceId: number, option: number): Promise<Models.Service> {
    return this.post(null, '/dont-approve-service/' + serviceId + '/' + option);
  }

  public checkCanApproveExtendedPeriod(serviceId: number): Promise<any> {
    return this.get('check-can-approve-extended-period/' + serviceId);
  }

  public approveExtendedPeriod(serviceId: number): Promise<Models.Service> {
    return this.post(null, '/approve-extended-period/' + serviceId + `?culture=${this.getLocale()}`);
  }

  public dontApproveExtendedPeriod(serviceId: number): Promise<Models.Service> {
    return this.post(null, '/dont-approve-extended-period/' + serviceId);
  }

  public completeServiceWithoutApproval(serviceId: number): Promise<Models.Service> {
    return this.post(null, '/complete-service-without-approval/' + serviceId);
  }

  public unlockService(serviceId: number): Promise<Models.Service> {
    return this.post(null, '/unlock/' + serviceId);
  }

  public updateQueue(serviceId: number, updateQueueData: any): Promise<any> {
    return this.post(updateQueueData, '/updatequeue/' + serviceId);
  }

  public getQueuedServices(serviceStationId: number): Promise<Array<any>> {
    return <any>this.get('GetQueuedservices/' + serviceStationId);
  }

  public getPreQueuedServices(
    serviceStationId: number,
    searchText: string,
    customerId?: number,
    plannedDeliveryDateFrom?: Date,
    plannedDeliveryDateTo?: Date,
    includeTested?: boolean,
    includeWashed?: boolean,
    includeRepaired?: boolean,
    includeAntifouled?: boolean,
    includePutOnStorage?: boolean,
    includePackedAndReady?: boolean,
    hasPlannedDeliveryDateOnly?: boolean,
    top?: number,
    skip?: number
  ): Promise<any> {
    return <any>(
      this.getResponse(
        'GetPreQueuedservices/' +
          serviceStationId +
          '?searchText=' +
          (searchText || '') +
          (customerId != null ? '&customerId=' + customerId : '') +
          (plannedDeliveryDateFrom != null ? '&plannedDeliveryDateFrom=' + plannedDeliveryDateFrom : '') +
          (plannedDeliveryDateTo != null ? '&plannedDeliveryDateTo=' + plannedDeliveryDateTo : '') +
          (includeTested != null ? '&includeTested=' + includeTested : '') +
          (includeWashed != null ? '&includeWashed=' + includeWashed : '') +
          (includeRepaired != null ? '&includeRepaired=' + includeRepaired : '') +
          (includeAntifouled != null ? '&includeAntifouled=' + includeAntifouled : '') +
          (includePutOnStorage === true ? '&includePutOnStorage=' + includePutOnStorage : '') +
          (includePackedAndReady === true ? '&includePackedAndReady=' + includePackedAndReady : '') +
          (hasPlannedDeliveryDateOnly != null ? '&hasPlannedDeliveryDate=' + hasPlannedDeliveryDateOnly : '') +
          (top != null ? '&top=' + top : '') +
          (skip != null ? '&skip=' + skip : '')
      )
    );
  }

  public regenerateServiceCard(serviceId: number): Promise<any> {
    return this.post(null, '/regenerate-service-card/' + serviceId + `?culture=${this.getLocale()}`);
  }

  public getServiceListDefaultFilters(pageSize): ServiceFilterModel {
    return {
      customerId: null,
      netId: null,
      searchText: null,
      orderBy: 'id',
      orderByDirection: 'DESC',
      top: pageSize,
      skip: null,
      serviceStationId: null,
      receivedFromSiteId: null,
      serviceStatusIds: null,
      receivedDateFrom: null,
      receivedDateTo: null,
      washedDateFrom: null,
      washedDateTo: null,
      testedDateFrom: null,
      testedDateTo: null,
      repairedDateFrom: null,
      repairedDateTo: null,
      antifouledDateFrom: null,
      antifouledDateTo: null,
      spaghettiDateFrom: null,
      spaghettiDateTo: null,
      hasPlannedDeliveryDate: null,
      plannedDeliveryDateFrom: null,
      plannedDeliveryDateTo: null,
      deliveredDateFrom: null,
      deliveredDateTo: null,
      declinedServiceApprovalFrom: null,
      declinedServiceApprovalTo: null,
      storageId: null,
      showInvoicedWashing: null,
      showInvoicedTesting: null,
      showInvoicedAntifouling: null,
      showInvoicedRepair: null,
      showInvoicedDelivery: null,
      showInvoicedAny: null,
      showNetsInStorage: null,
      showSpecialProducts: null,
      measuredDateFrom: null,
      measuredDateTo: null,
      approvedExtendedPeriodFrom: null,
      approvedExtendedPeriodTo: null,
    };
  }

  getAllServiceFiles(serviceId: number) {
    return this.httpClient.exec<FileAttachmentModels.FileAttachment[]>(
      Models.Service.ApiUrl + '/get-all-files-for-service/' + serviceId,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    );
  }

  public getServiceFiltersListDefault() {
    return ['customerId', 'meshsize', 'hasPlannedDeliveryDate', 'netsInStorage', 'netType', 'storageLocation'];
  }

  /** */
  public async getServiceUsers(serviceId: number) {
    return ((await this.get(`${serviceId}/service-users`)) as any).data as Promise<Models.ServiceUser[]>;
  }

  public async getServiceInvoiceStaatus(serviceId: number) {
    return (await this.get(`${serviceId}/invoice-status`)) as any as Promise<ServiceInvoiceStatus>;
  }
}
