import { autoinject } from 'aurelia-framework';
import { ServiceSpecialProductWashProduct } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ServiceSpecialProductWashProductService extends BaseApiService<ServiceSpecialProductWashProduct> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, ServiceSpecialProductWashProduct);
  }

  getAllWithProduct() {
    return this.get('?$expand=Product,SpecialProductType') as unknown as Promise<ServiceSpecialProductWashProduct[]>;
  }
}
