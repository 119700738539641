import { autoinject } from 'aurelia-framework';
import { ServiceInvoiceStandardProduct } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ServiceInvoiceStandardProductService extends BaseApiService<ServiceInvoiceStandardProduct> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, ServiceInvoiceStandardProduct);
  }

  getAllWithProduct() {
    return this.get(`?$expand=Product`) as unknown as Promise<ServiceInvoiceStandardProduct[]>;
  }
}
