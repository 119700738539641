import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';

@autoinject
export class TranslationService {
  constructor(private i18n: I18N) {
    
  }

  public getLocalizedValue(object: any, key: string): string {
    if (!object) {
      return '';
    }

    let lang = this.i18n.getLocale();

    switch (lang) {
      case 'nb-NO':
        return object[key];
      case 'en':
        return object[key + 'En'] ? object[key + 'En'] : object[key];
      case 'es':
        return object[key  + 'Es'] ? object[key  + 'Es'] : object[key + 'En'];
      default:
        return object[key];
    }
  }
}
