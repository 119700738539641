import { autoinject } from 'aurelia-framework';
import { ServiceStationLabourProducts } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ServiceStationLabourProductService extends BaseApiService<ServiceStationLabourProducts> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, ServiceStationLabourProducts);
  }
}
