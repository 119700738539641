import { autoinject } from 'aurelia-framework';
import { AuthHttpClient } from 'services/auth-http-client';
import { Settings, UpdateSettings } from 'models/Settings';

@autoinject
export class SettingsService {
  private readonly baseUrl = 'api/settings';

  constructor(private httpClient: AuthHttpClient) {}

  public getSettings() {
    return this.httpClient.exec<Settings>(this.baseUrl, {
      method: 'GET',
      headers: {},
    });
  }

  public update(settings: UpdateSettings) {
    return this.httpClient.exec<Settings>(this.baseUrl, {
      method: 'PATCH',
      body: JSON.stringify(settings),
      headers: {},
    });
  }
}
