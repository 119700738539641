import { autoinject } from 'aurelia-framework';
import { FileAttachmentModels } from 'models/FileAttachmentModels';
import { AuthHttpClient } from 'services/auth-http-client';
import saveAs from 'file-saver';

@autoinject
export class SystemFileStorageService {
  private readonly baseUrl = 'api/system-storage';
  private readonly metaUrl = (fileId: number | string) => `api/system-storage/${fileId}/metadata`;
  private readonly getUrl = (fileId: number | string) => `api/system-storage/${fileId}`;

  constructor(private httpClient: AuthHttpClient) {}

  public getMetaData(id: any) {
    return this.httpClient.exec<FileAttachmentModels.FileAttachment>(this.metaUrl(id), {
      method: 'GET',
      headers: {},
    });
  }

  public async download(id: any, save = false) {
    const response = await this.httpClient.execNoTransform(this.getUrl(id), {
      method: 'GET',
      headers: {},
    });

    let filename = '';
    const disposition = response.headers.get('content-disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    if (save) {
      return response.blob().then((blob) => {
        saveAs(blob, filename);
      });
    } else {
      return await response.blob();
    }
  }
}
