/* eslint-disable @typescript-eslint/no-explicit-any */
import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';
import * as moment from 'moment';
import { ServiceReportDetail, ServiceReportModels, ServiceStationWeekReport } from 'models/service-report-models';

@autoinject
export class ServiceReportService extends BaseApiService<ServiceReportModels.ServiceReports> {
  constructor(
    httpClient: AuthHttpClient,
    private i18n: I18N
  ) {
    super(httpClient, ServiceReportModels.ServiceReports);
  }

  private getLocale(): string {
    let locale = this.i18n.getLocale();
    if (locale === 'nb-NO') {
      locale = 'nb';
    }

    return locale;
  }

  public getServiceStatistics(
    serviceStationId: number,
    dateFrom: Date,
    dateTo: Date,
    exportReport: boolean
  ): Promise<Array<any>> {
    let query =
      'get-service-statistics?dateFrom=' +
      moment.default(dateFrom).format('YYYY-MM-DD') +
      '&dateTo=' +
      moment.default(dateTo).format('YYYY-MM-DD');

    if (serviceStationId) {
      query += '&serviceStationId=' + serviceStationId;
    }

    if (exportReport) {
      query += '&export=true';
      return <any>this.getBlob(query);
    } else {
      return <any>this.get(query);
    }
  }

  public getServiceStationStatisticsHourDetails(
    serviceStationId: number,
    dateFrom: Date,
    dateTo: Date
  ): Promise<Array<any>> {
    let query =
      'get-service-statistics-hour-details?dateFrom=' +
      moment.default(dateFrom).format('YYYY-MM-DD') +
      '&dateTo=' +
      moment.default(dateTo).format('YYYY-MM-DD');

    if (serviceStationId) {
      query += '&serviceStationId=' + serviceStationId;
    }

    return <any>this.get(query);
  }

  public getServiceKpiResults(
    serviceStationId: Array<string>,
    year: number,
    exportKpis: boolean,
    withNewNetImpregnations: boolean
  ): Promise<Array<any>> {
    const params = new URLSearchParams();
    params.append('year', year.toString());

    if (serviceStationId.length > 0) {
      params.append('selectedServiceStationIds', serviceStationId.map((it) => it).join(','));
    }

    if (withNewNetImpregnations) {
      params.append('withNewNetImpregnations', 'true');
    }

    let path = 'get-service-kpi-result?';
    if (exportKpis) {
      params.append('export', 'true');
      return <any>this.getBlob(path + params.toString());
    } else {
      return <any>this.get(path + params.toString());
    }
  }

  public getServiceBacklog(
    serviceStationId: number,
    exportBacklog: boolean,
    requirePlannedDeliveryDate = false
  ): Promise<Array<any>> {
    let query = `get-service-station-backlog?requirePlannedDeliveryDate=${requirePlannedDeliveryDate}`;

    if (serviceStationId) {
      query += '&serviceStationId=' + serviceStationId;
    }

    if (exportBacklog) {
      query += '&export=true';
      return <any>this.getBlob(query);
    } else {
      return <any>this.get(query);
    }
  }

  public getServiceStationBacklogDetails(
    serviceStationId: number,
    taskCode,
    isSpecialProduct,
    netTypeId,
    specialProductTypeId,
    requirePlannedDeliveryDate
  ): Promise<Array<any>> {
    let query = `get-service-station-backlog-details?taskCode=${taskCode}&serviceStationId=${serviceStationId}&isSpecialProduct=${isSpecialProduct}&netTypeId=${netTypeId}&specialProductTypeId=${specialProductTypeId}&requirePlannedDeliveryDate=${requirePlannedDeliveryDate}`;
    return <any>this.get(query);
  }

  public getServiceMonthReport(
    serviceStationId: number,
    year: number,
    month: number,
    exportBacklog: boolean
  ): Promise<Array<any>> {
    let query = 'get-service-station-month-report?year=' + year + '&month=' + month;

    if (serviceStationId) {
      query += '&serviceStationId=' + serviceStationId;
    }

    if (exportBacklog) {
      query += '&export=true';
      return <any>this.getBlob(query);
    } else {
      return <any>this.get(query);
    }
  }

  public getServiceStationMonthReportDetails(
    serviceStationId: number,
    year: number,
    month: number,
    task: string,
    type: string,
    netTypeId?: number,
    specialProductTypeId?: number
  ): Promise<Array<any>> {
    const query = `get-service-station-month-report-details?year=${year}&month=${month}&serviceStationId=${serviceStationId}&netTypeId=${netTypeId}&specialProductTypeId=${specialProductTypeId}&task=${task}&type=${type}`;
    return <any>this.get(query);
  }

  public getServiceStationWeekReportDetails(
    serviceStationId: number,
    week: number,
    month: number,
    year: number,
    type: string,
    netTypeId?: number,
    specialProductTypeId?: number
  ): Promise<Array<ServiceReportDetail>> {
    const query = `get-service-station-week-report-details?year=${year}&week=${week}&month=${month}&serviceStationId=${serviceStationId}&netTypeId=${netTypeId}&specialProductTypeId=${specialProductTypeId}&type=${type}`;
    return <any>this.get(query);
  }

  public getServiceWeekReport(
    serviceStationIds: (string | number)[],
    year: number,
    weeks: string,
    month?: number,
    exportBacklog?: boolean
  ): Promise<ServiceStationWeekReport[]> {
    let query = 'get-service-station-week-report?year=' + year + '&weeks=' + weeks;
    if (!serviceStationIds || !serviceStationIds.length) serviceStationIds = [''];
    query += '&serviceStationIds=' + serviceStationIds?.join(',') || '';
    if (month !== undefined) query += `&month=${month}`;
    return this.get(query) as Promise<ServiceStationWeekReport[]>;
  }
}
