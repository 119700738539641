import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ServiceRepairService extends BaseApiService<Models.ServiceRepair> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.ServiceRepair);
    }

    public updateComment(serviceRepairId: number, oldComment: string, newComment: string) {
        return this.post({ OtherOld: oldComment, OtherNew: newComment } as any, '/updatecomment/' + serviceRepairId);
    }
}
