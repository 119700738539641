import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ServiceMeasurementService extends BaseApiService<Models.ServiceMeasurement> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.ServiceMeasurement);
    }

    public saveMeasurements(serviceId: number, measurementData: any): Promise<any> {
        return this.post((measurementData), '/save-measurements/' + serviceId);
    }

    public getMeasurements(serviceId: number): Promise<any> {
        return this.get('get-measurements/' + serviceId);
    }   

    public getCalculatedMeasurements(serviceId: number): Promise<Models.ServiceMeasurement[]> {
        return this.get('get-calculated-measurements/' + serviceId) as unknown as Promise<Models.ServiceMeasurement[]>;
    }
}
