import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ServiceCheckpointService extends BaseApiService<Models.ServiceCheckpoint> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.ServiceCheckpoint);
    }

    public getCheckpoints(serviceId): Promise<any> {
        return this.get(`getcheckpoints/${serviceId}`);
    }

    public updateCheckpoint(checkpoint): Promise<any> {
        return this.post(checkpoint, `/${checkpoint.Id}/update-checkpoint`);
    }
}
