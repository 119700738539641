import { autoinject } from 'aurelia-framework';
import { ServiceInvoiceSpagettiProduct } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ServiceInvoiceSpagettiProductService extends BaseApiService<ServiceInvoiceSpagettiProduct> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, ServiceInvoiceSpagettiProduct);
  }

  getAllWithProduct() {
    return this.get('?$expand=Product') as unknown as Promise<ServiceInvoiceSpagettiProduct[]>;
  }
}
