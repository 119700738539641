import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { SearchModels } from 'models/SearchModels';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class SearchService extends BaseApiService<SearchModels.GroupedSearchResult> {
    constructor(httpClient: AuthHttpClient, private router: Router, private i18n: I18N) {
        super(httpClient, SearchModels.GroupedSearchResult);
    }

    public search(searchText: string): Promise<Array<SearchModels.GroupedSearchResult>> {
        let locale = this.i18n.getLocale();
        return this.getAll(`/${searchText}?locale=${locale}`)
            .then(serverSearchResult => {
                // return search from modules/routes combined with search on server
                return this.searchModules(searchText).concat(serverSearchResult);
            });
    }

    public searchModules(searchText: string) {
        // search modules/routes the user has access to that matches the title on the route
        let searchableRoutes = 
            this.router.routes.filter(x => 
                x.navModel.settings 
                && x.navModel.settings.searchable 
                && x.navModel.settings.availableForUser);

        let filteredModules = 
            <Array<any>>searchableRoutes
                .filter(x => x.navModel['titleTranslated'] && x.navModel['titleTranslated'].toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
                .sort((a, b) => (a.navModel['titleTranslated'] > b.navModel['titleTranslated']) ? 1 : ((b.navModel['titleTranslated'] > a.navModel['titleTranslated']) ? -1 : 0));

        // create a "fake" search result on the same format as the server search result
        // to simplify the search component
        if (filteredModules.length > 0) {
            filteredModules.forEach(x => {
                x.Entity = 'module'
                x.Result = x.navModel['titleTranslated']
            });
        
            let moduleResult: Array<SearchModels.GroupedSearchResult> = [{
                Entity: 'module',
                Result: filteredModules
            }];
            
            return moduleResult;
        }

        return [];
    }
}
