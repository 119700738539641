import { autoinject } from 'aurelia-framework';
import { NetlogHttpClient } from 'lib/http';
import { SiteStatus } from 'models';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class SiteService extends BaseApiService<Models.Site> {
  constructor(
    httpClient: AuthHttpClient,
    private client: NetlogHttpClient
  ) {
    super(httpClient, Models.Site);
  }

  checkExistingSite(customerId: number, name: string): Promise<any> {
    return this.getAll(`/check-existing-locality?customerId=${customerId}&name=${name}`);
  }

  getByCustomer(customerId: number): Promise<Array<any>> {
    return this.getAll(`/get-by-customer/${customerId}`);
  }

  getAquacomLocation(siteId: number): Promise<Models.Site> {
    return this.get('/get-aquacom-location/' + siteId);
  }

  getSiteStatus(siteId: number) {
    return this.client.get({
      url: `${Models.Site.ApiUrl}/${siteId}/status`,
      model: SiteStatus,
    });
  }
}
