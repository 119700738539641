import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';
import { I18N } from 'aurelia-i18n';
@autoinject
export class StorageInvoiceService extends BaseApiService<Models.StorageInvoice> {

    public manualFilters = [
      'top',
      'skip',
      'export',
      'orderBy',
      'orderByDirection',
    ];

    constructor(httpClient: AuthHttpClient, private i18n: I18N) {
        super(httpClient, Models.StorageInvoice);
    }

    public getItems(
      filters: object | any
    ): Promise<any> {
      let queryString: string = '';
  
      if (filters.serviceStatusIds) {
        queryString += '?serviceStatusIds=' + filters.serviceStatusIds;
      } else {
        queryString += '?serviceStatusIds=';
      }
  
      let props = Object.getOwnPropertyNames(filters);
  
      props.forEach((key) => {
        // add querystring params for all criterias, but ignore props that start with _
        if (
          filters[key] != null &&
          !this.manualFilters.find((x) => x === key) &&
          key.indexOf('_') !== 0
        ) {
          queryString += '&' + key + '=' + filters[key];
        }
      });
  
      if (filters._select) {
        queryString += `&select=${filters._select},ServiceStationId`;
      }
  
      queryString += '&locale=' + this.getLocale();
  
      if (filters.orderBy) {
        queryString += '&orderBy=' + filters.orderBy;
  
        if (filters.orderByDirection) {
          queryString += '&orderByDirection=' + filters.orderByDirection;
        }
      }
  
      if (filters._export) {
        queryString += '&export=' + filters._export;
  
        return this.getBlob('items' + queryString);
      } else {
        if (filters.top) {
          queryString += '&top=' + filters.top;
        }
        if (filters.skip) {
          queryString += '&skip=' + filters.skip;
        }
  
        return this.getResponse('items' + queryString) as any;
      }
    }

    private getLocale(): string {
      let locale = this.i18n.getLocale();
      if (locale === 'nb-NO') {
        locale = 'nb';
      }
  
      return locale;
    }

    public undo(storageInvoiceId: number) {
      return super.post({storageInvoiceId} as any, '/undo');
    }
}
