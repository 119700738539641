import { autoinject } from 'aurelia-framework';
import { SinkerTube } from 'models/ExtraEquipment';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class SinkerTubeService extends BaseApiService<SinkerTube> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, SinkerTube);
  }
}
