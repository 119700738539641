import { autoinject } from 'aurelia-framework';
import { ServiceInvoiceWashWasteNetTypeProduct } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ServiceInvoiceWashWasteNetTypeProductService extends BaseApiService<ServiceInvoiceWashWasteNetTypeProduct> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, ServiceInvoiceWashWasteNetTypeProduct);
  }

  getAllWithProduct() {
    const query = `?$expand=WashProduct,WasteProduct,NetType`;

    return this.get(query) as unknown as Promise<ServiceInvoiceWashWasteNetTypeProduct[]>;
  }
}
